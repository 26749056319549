<template>
  <a-drawer width="45%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form" :rules="rules">
      <a-form-model-item prop="name" :label="$t('服务保障.参数名称')">
        <a-input v-model="form.name" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('服务保障.参数名称')"/>
      </a-form-model-item>

      <a-form-model-item :label="$t('商品标签.状态')" prop="status">
        <a-radio-group v-model="form.status" button-style="solid">
          <a-radio-button v-for="(item, index) in this.customDict.StatusEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <a-form-item :label="$t('服务保障.参数值')" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" class="margin-bottom-10">
        <div class="margin-bottom-10">
          <a-button type="primary" @click='addLable'>
            添加内容描述
          </a-button>
        </div>
        <a-table :data-source="form.siteGuaranteeSlotList" :columns="timeColumns" rowKey="id" :pagination="false" bordered>
          <div slot="startTimePeriod" slot-scope="text, record">
            <a-input v-model="record.lableName" :placeholder="$t('通用.输入.请输入')+$t('商品标签.标签名称')" />
          </div>
          <div slot="action" slot-scope="text, record">
            <a-popconfirm title="确定要删除此参数值吗？" ok-text="确定" cancel-text="取消" @confirm="delTime(record)">
              <a href="javascript:void(0)" style="color:red;">删除</a>
            </a-popconfirm>
          </div>
        </a-table>
      </a-form-item>

      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl"/>
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{ $t('通用.按钮.保存') }}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{ $t('通用.按钮.取消') }}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>

import { getGuarantee, addGuarantee, updateGuarantee } from '@/api/goods/guarantee'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject} from "@/api/tool/oss";

export default {
  name: 'CreateForm',
  props: {},
  components: {
    CustomDictTag,
  },
  data() {
    return {
      labelCol: {span: 4},
      wrapperCol: {span: 14},
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      timeColumns: [
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index'
        },
        {
          title: '参数值',
          dataIndex: 'lableName',
          key: 'lableName',
          scopedSlots: {customRender: 'startTimePeriod'}
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          slots: {title: 'addbtn', customRender: 'name'},
          scopedSlots: {customRender: 'action'}
        }
      ],
      // 表单参数
      form: {
        id: null,
        siteGuaranteeSlotList: [],

        name: null,

        status: 0,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        name: [
          {required: true, message: this.$t('服务保障.参数名称') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        status: [
          {required: true, message: this.$t('服务保障.状态1-启用 0-禁用') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ]
      }
    }
  },
  filters: {},
  created() {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
      this.reset()
    },
    addLable() {
      let key = this.form.siteGuaranteeSlotList.length-1
      if(this.form.siteGuaranteeSlotList.length > 0 && this.form.siteGuaranteeSlotList[key].lableName==''){
        this.$message.info('请先填写参数值！')
        return false;
        
      }
      const index = this.form.siteGuaranteeSlotList.length + 1;
      const newData = {
        index: index,
        lableName: "",
        price: null
      };
      this.form.siteGuaranteeSlotList.push(newData);
      this.count = index;
    },
    startTimePeriodChange(date, dateString,record){
      record.lableName = dateString
    },
    /**
     * 删除等级
     */
    delTime(info) {
      this.form.siteGuaranteeSlotList = this.form.siteGuaranteeSlotList.filter(item => item.index !== info.index);
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset() {
      this.formType = 1

      this.form = {
        id: null,
        siteGuaranteeSlotList: [],
        name: null,
        status: 0,
        createTime: null,
        remark: null,
      }

    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getGuarantee({"id": id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateGuarantee(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addGuarantee(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
